.sidebar-container {
  background: #f4f4f4;
  border-radius: 10px;
  width: 220px;
  height: 100%;
  position: fixed;
  overflow: auto;
  padding: 30px 0 120px;
  transition: var(--transition);
}

.sidebar-container-hide {
  background: #f4f4f4;
  border-radius: 10px;
  width: 220px;
  height: 100%;
  position: fixed;
  overflow: auto;
  padding: 30px 0;
  z-index: 2000;
  transition: var(--transition);
}

.flex-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 530px;
}

.sidebar-logo {
  text-align: center;
  padding: 0 20px 30px;
}

.sidebar-logo img {
  width: 40px;
}

.sb-divider {
  background: #d4d4d4;
  margin-top: 2rem;
}

.links,
.sb-links {
  font-size: 14px;
}

.module-link,
.sb-links ul {
  padding-left: 1rem;
  margin-bottom: 0;
}

.module-link a,
.sb-links a {
  padding: 15px 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.6rem;
  color: black;
  font-weight: 300;
  margin-top: 15px;
  /* font-family: "Sen", sans-serif; */
}

a.active {
  background: black;
  border-radius: 40px 0 0 40px;
  color: white;
}

.sb-links {
  margin-top: 5rem;
}

.button-container {
  margin-top: 3rem;
  padding: 0 20px;
}

.button-container .logout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.open-close-icon {
  display: none;
}

@media screen and (max-width: 950px) {
  .sidebar-container {
    width: 90px;
    left: 10px;
    z-index: 2000;
    box-shadow: var(--shadow);
  }

  .sidebar-container-hide {
    background: #f4f4f4;
    border-radius: 10px;
    width: unset;
    transition: var(--transition);
  }

  .module-link,
  .sb-links ul {
    padding-left: 0.5rem;
    margin-bottom: 0;
  }

  .module-link li,
  .sb-links li,
  .button-container span {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .sidebar-container-hide {
    background: #f4f4f4;
    border-radius: 10px;
    width: 90px;
    height: 100vh;
    position: fixed;
    left: -100px;
    overflow: auto;
    padding: 30px 0;
    z-index: 2000;
    transition: var(--transition);
  }

  .open-close-icon {
    display: block;
    position: absolute;
    left: 10px;
    top: 20px;
    z-index: 3000;
    background: transparent;
    font-size: 21px;
    width: 30px;
  }
}
