.dashboard-container {
    padding: 20px;
}

.main-container {
    position: fixed;
    left: 250px;
    right: 10px;
    background: #F4F4F4;
    border-radius: 10px;
    padding: 20px;
    height: 100vh;
    overflow: auto;
    padding-bottom: 200px;
}

@media screen and (max-width: 950px) {
    .main-container {
        left: 120px;
    }
}

@media screen and (max-width: 500px) {
    .main-container {
        left: 10px;
    }
}