.nav-container {
    background: #E9E9E9;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
}

.nav-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


