.home-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0;
}

.left-contents {
    height: 100vh;
    width: 50%;
    background-image: url(../../assets/images/login-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: white;
    text-align: center;
    padding: 50px;
}

.left-contents h5 {
    font-size: 3rem;
    margin-top: 15rem;
}

.left-contents p {
    color: rgb(197, 197, 197);
}

.right-contents {
    width: 50%;
    height: 100vh;
    background: #dedede;
    text-align: center;
    padding: 50px;
}

.right-contents h3 {
    font-size: 3rem;
    margin-top: 13rem;
}

.act-btn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.act-btn button {
    width: 60%;
}

.create-act-btn {
    background-color: black;
}

.create-act-btn:hover {
    background-color: rgb(65, 65, 65);
}

@media screen and (max-width: 850px) {
    .left-contents {
        display: none;
    }
    .right-contents {
        width: 100%;
    }
}