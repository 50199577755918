* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--bg-color);
  color: var(--text-color);
}

:root {
  --bg-color: #fff;
  --bg-color2: #fff;
  --primary-color: #d30c0c;
  --fade-primary-color: #dc6a6a;
  --secondary-color: #fff;
  --text-color: #5b5b5b;
  --bg-footer: #171717;
  --transition: 0.5s ease-in-out;
  --shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

p {
  color: var(--text-color);
  margin-bottom: 0;
  font-family: 'Sen', sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}


.custom-container {
  width: 100%;
  /* height: 100vh; */
  padding: 5rem 200px;
}

.divider {
  height: 1px;
  width: 100%;
  background: var(--text-color);
}

.button {
  border: none;
  color: var(--secondary-color);
  font-weight: 500;
  padding: 15px;
  transition: var(--transition);
  width: max-content;
  border-radius: 5px;
  cursor: pointer;
}

.primary-btn {
  background: var(--primary-color);
}

.outline-btn {
  background: transparent;
  border: 1px solid white;
  width: 200px;
  border-radius: 10px;
}

.outline-btn:hover {
  background: white;
  color: black;
}

.logout-btn {
  background-color: black;
  width: 100%;
}

.logout-btn:hover {
  background-color: rgb(40, 40, 40);
}

.secondary-btn {
  background: var(--bg-color);
}

.secondary-btn:hover {
  background: var(--text-color);
}

.textfield {
  background: transparent;
  border: none;
  border-radius: 0;
  outline: 0;
  border-bottom: 1px solid var(--text-color);
  color: var(--text-color);
  padding: 10px 0;
}

::placeholder {
  color: var(--text-color);
}

.error {
  background: rgb(247, 217, 217);
  border: 1px solid rgb(251, 168, 168);
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: rgb(240, 97, 97);
  font-family: 'Sen', sans-serif;
  text-align: center;
  margin-top: 1rem;
}


/* MEDIA QUERY */
@media screen and (max-width: 1440px) {
  .custom-container {
      padding: 5rem 100px;
  }
}

@media screen and (max-width: 700px) {
  .custom-container {
      padding: 5rem 50px;
  }
}

@media screen and (max-width: 600px) {
  .outline-btn {
      padding: 10px;
      font-size: 10px;
      width: 100px;
  }
}


@media screen and (max-width: 420px) {
  .custom-container {
      padding: 5rem 20px;
  }
}


