.course-cover {
    background: url(../../assets/images/music-bg.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    padding: 20px;
    margin-top: 1rem;
    color: white;
}

.course-cover p {
    color: white;
}

.course-cover h2 {
    margin: 30px 0;
}

/* .instructor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin: 20px 0 50px;
}

.instructor-avatar {
    background-image: url(../../assets/images/instructor.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    width: 20px;
    height: 20px;
} */

.course-lessons {
    margin-top: 1rem;
    width: 100%;
    height: 400px;
    overflow: auto;
    padding: 0 0 30px 0;
}

.course-lessons a {
    color: unset !important;
    cursor: pointer;
}

.lesson {
    background: #E9E9E9;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 0.5rem;
}

.lesson-stats, .lesson-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
}

.lesson-stats p {
    font-size: 14px;
    color: #b2b2b2 !important;
}

p.completed, .lesson-contents span {
    color: rgb(0, 157, 0) !important;
}

.lesson-contents {
    margin-top: 1rem;
}

.lesson-contents h6 {
    font-size: 14px;
}

.course-contents {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

.course-d {
    width: 50%;
    padding: 20px 0;
}

.course-detail {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    align-items: flex-start;
    gap: 0.6rem;
    text-align: center;
}

.course-detail i {
    font-size: 14px;
    margin-right: 10px;
    color: rgb(114, 114, 114);
}

.date-updated i {
    font-size: 20px;
}

.level i {
    font-size: 15px;
}

.language i {
    font-size: 18px;
}

.award i {
    font-size: 24px;
}

.action-btns {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.action-btns a {
    color: white;
  }

  @media screen and (max-width: 850px) {
    .course-contents {
        flex-direction: column;
    }

    .course-lessons, .course-d {
        width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .course-cover h2 {
        font-size: 18px;
    }

    .action-btns a span {
        display: none;
    }
  }