.avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 20%;
    cursor: pointer;
    /* background-color: rgb(0, 0, 0); */
}

.avatar-container img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}