.container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.slider {
  flex: 1 1 300px;
  padding: 20px;
  background-color: #ffffff; /* Set the background color to white */
  /* margin-top: 40px; */
  height: 300px; /* Set the desired height */
}

.slider h3 {
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

.lesson {
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.lesson.selected {
  background-color: #e0e0e0;
}

.lesson.watched {
  background-color: #c5e1a5;
}

.videoPlayer {
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 400px;
  position: relative;
  background-color: #000;
  margin-top: 40px;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.noLesson {
  margin-top: 40px;
  font-size: 18px;
  color: #888;
}

.lessonInformation {
  flex: 1 1 100%;
  padding: 20px;
  background-color: #f1f1f1;
  margin-top: 20px;
}

.lessonInformation h3 {
  margin-bottom: 10px;
}

.lessonInformation p {
  margin-bottom: 20px;
}

.lessonMetadata {
  margin-top: 20px;
}

.lessonMetadata h4 {
  margin-bottom: 10px;
}

@media (min-width: 769px) {
  .container {
    flex-wrap: nowrap;
  }

  .slider {
    flex: 0 0 300px;
    margin-right: 20px;
  }

  .videoPlayer {
    flex: 1 1 auto;
    margin-top: 0;
  }
}

/* Additional styling for lesson information */
@media (max-width: 768px) {
  .lessonInformation {
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
