.course-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.course-detail {
    background-color: #ebebeb;
    border-radius: 10px;
    padding: 20px;
}   

.course-detail h6 {
    margin: 0.5rem 0;
}   

ul.course-info-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 2rem;
}

ul.course-info-list li {
    list-style-type: disc !important;
    color: #999;
}

.start-course-btn {
    width: 150px;
    margin-top: 1rem;
}

.sample-pack-container {
    background-image: url(../assets/images/kma-sp-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

h6.sp-name {
    color: white;
    margin: 0.5rem 0;
}

.dld-btn {
    background: #FFA800;
}

@media screen and (max-width: 800px) {
    .course-information {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }
}