.login-container {
    background: rgb(237, 237, 237);
}

.reset-container {
    background: rgb(237, 237, 237);
    height: 100vh;
}

.login-sub-container {
    background-color: white;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    width: 40%;
    margin: auto;
    padding: 30px;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.column-flex {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.form-container input, .form-container select {
    padding: 12px;
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 5px;
}

.submit-btn button {
    width: 100%;
    margin-top: 0.8rem;
}

::placeholder {
    color: #7a7a7a;
}

a {
    color: #FFA800;
}


@media screen and (max-width: 1300px){
    .login-sub-container {
        width: 60%;
    }
}

@media screen and (max-width: 900px){
    .login-sub-container {
        width: 90%;
    }
}

@media screen and (max-width: 600px){
    .login-sub-container {
        width: 100%;
    }
}

